import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Box,
  Button,
  Container,
  Link,
  Typography,
  useMediaQuery
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import PageTitle from "@components/PageTitle";
import { Image } from "@components/Image/styles";
import JICLogo from "@assets/images/image-jicLogo.png";
import { getLinkFullUrl } from "@pages/EstatePlanView/Partials/utils";
import { MARKETING_PAGE_URL } from "@setup/consts/misc";

const WaitlistPage: React.FC = () => {
  const intl = useIntl();

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <PageTitle
        title={intl.formatMessage({
          defaultMessage: "JIC Estates | Waitlist"
        })}
      />
      <Container
        maxWidth="sm"
        sx={{
          py: { xs: 2.5, md: 6.25 }
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          mb="0.5rem"
        >
          <Image src={JICLogo} alt="JIC Estates Logo" />
        </Box>

        <Box textAlign="center">
          <Typography
            variant="h1"
            lineHeight="1.5"
            letterSpacing="0"
            mb="1.25rem"
          >
            We&apos;re not accepting new members at this time.
          </Typography>

          <Box>
            <Typography mb="0.625rem" variant="body1">
              If you are an existing JIC Estates member and would like
              to make changes to your estate plan, please contact
              support@justincaseestates.com for assistance.
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            gap: "1.25rem",
            my: "1.25rem"
          }}
        >
          <Link
            href={getLinkFullUrl([MARKETING_PAGE_URL])}
            target="_blank"
          >
            <Button variant="outlined" size="medium">
              <FormattedMessage defaultMessage="<-- Home" />
            </Button>
          </Link>
          <Link
            href="mailto:support@justincaseestates.com"
            target="_blank"
          >
            <Button variant="contained" size="medium">
              <FormattedMessage defaultMessage="Contact JIC Estates" />
            </Button>
          </Link>
        </Box>
      </Container>
    </>
  );
};

export default WaitlistPage;
